<template>
  <div class="container new-container news-detail">
    <el-breadcrumb
      separator="/"
      style="margin-bottom:15px"
    >
      <el-breadcrumb-item :to="{ path: '/news' }">新闻动态</el-breadcrumb-item>
      <el-breadcrumb-item>{{news.title}}</el-breadcrumb-item>
    </el-breadcrumb>

    <el-empty
      v-if="!news || !news.title"
      :image-size="200"
    ></el-empty>

    <template v-else>
      <h1>{{news.title}}</h1>
      <div class="news-date">
        <span>{{news.author}}</span>
        时间：{{news.createTime}}
      </div>
      <div
        class="news-cnt"
        v-html="news.content"
      ></div>
      <File-download
        v-for="item in news.files"
        :key="item.id"
        :fileName="item.fileName"
        :fileUrl="item.fileUrl"
      ></File-download>
    </template>
  </div>
</template>
<script>
import { getNewsDeail } from '@/api/common';
import FileDownload from './FileDownload.vue'

export default {
  name: "newsDetail",
  components: { FileDownload },
  data() {
    return {
      id: this.$route.query.id,
      news: {}
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      if (!this.id) return;

      getNewsDeail({ id: this.id }).then(res => {
        if (res.success) {
          this.news = res.result
          this.news.files = JSON.parse(res.result.attachData);
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.new-container {
  width: 1000px;
  margin: 20px auto;
  color: #6f6f6f;
}
.news-cnt {
  min-height: 400px;
}
.news-detail h1 {
  color: #000000;
  font-size: 20px;
  text-align: center;
}
.news-date {
  text-align: right;
  margin: 10px 0px;
}
.news-date span {
  padding-right: 20px;
}
</style>