<template>
  <div class="file-block">
    <i class="el-icon-wallet m-icon"></i>
    <span
      class="ellipsis"
      :title="fileName?fileName:'附件'"
    >{{fileName?fileName:'附件'}}</span>
    <el-button
      v-if="fileUrl"
      @click="handleDownloadFile"
      style="float:right"
      type="primary"
      size="mini"
      round
    >资料下载</el-button>
    <div class="clearfix"></div>
  </div>
</template>
<script>
import { download } from '@/utils/index'

export default {
  name: 'fileDownload',
  props: {
    fileName: {
      type: String,
      default: '',
      //required: true
    },
    fileUrl: {
      type: String,
      default: null,
      // required: true
    }
  },
  data() {
    return {

    }
  },
  methods: {
    handleDownloadFile() {
      if (this.fileUrl) {
        //window.location.href = this.fileUrl //todo 接口下载
        download(this.fileUrl, this.fileName ? this.fileName : '附件')
      }
      else {
        console.log("文件地址为空了...")
      }


    }
  }
}
</script>
<style lang="scss" scoped>
.file-block {
  padding: 8px;
  border: 1px solid #bbbbbb;
  border-radius: 5px;
  margin-bottom: 10px;
  .m-icon {
    position: relative;
    top: 2px;
  }
  span {
    padding-left: 10px;
    color: #999999;
    font-size: 14px;
    position: relative;
    top: 2px;
    display: inline-block;
    max-width: calc(100% - 110px);
  }
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}

.clearfix:after {
  clear: both;
}
</style>